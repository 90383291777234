































import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import { readToken } from '@/store/main/getters';

@Component
export default class PairwiseList extends Vue {
  public loading = false;

  public goToCreate() {
    this.$router.push('/main/user/pairwise/new');
  }

  public headers = [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
    },
    { text: 'Title', value: 'title' },
    { text: 'Creation Date', value: 'created' },
  ];
  public items: {
    id: number;
    uuid: string;
    title: string;
    created: Date;
  }[] = [];

  public async mounted() {
    try {
      const response = await api.getPairwiseSurveyList(readToken(this.$store));
      if (response.status === 200) {
        this.items = response.data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  public handleRowClick(e, row) {
    this.$router.push(`/main/user/pairwise/${row.item.id}`);
  }
}

